import Spacer from '@berlitz/spacer'
import { Tick } from '@berlitz/icons'
import Button from '@components/Button'
import { Lesson } from '@components/LPCard'
import { Tag, Icon, IconCircle } from '@components/LPCard/Common'
import ActivityRounds from '@components/LPCard/Common/ActivityRounds'
import {
  CleanHeader,
  Lesson0Desc,
  Lesson0Wrapper,
  LessonDesc,
  LessonWrapper,
  Subtitle,
  TagContainer,
  AIWrapper,
} from '@components/LPCard/Lesson/style'
import { StoreProps } from '@interfaces/StoreState'
import { LCRedoLocalstorageKey } from '@utils/constants'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IPCompleted } from './interface'
import RedoModal from './RedoModal'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import { useMutation } from '@apollo/react-hooks'
import { RESET_FLEX } from '@mutations/activity'
import PortalStudentProfile from '@classes/StudentProfile'
import AITutorLink from '../Common/AITutorLink'

const Completed: React.FC<IPCompleted> = ({ title, fullname, activities = [], link, isRedo, continueLink, unit }) => {
  const isLessonZero = unit?.IsLessonZero
  const cardTitle = (fullname || '').trim()
  const SFID = useSelector(({ userProfile }: StoreProps) => userProfile?.info?.SFId)
  const student = useSelector(
    ({ userProfile }: StoreProps) => new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo)
  )
  const learningPath = useSelector(({ activeState }: StoreProps) => activeState.learningPath)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalDisabled, setModalDisabled] = React.useState(localStorage.getItem(LCRedoLocalstorageKey(SFID)) === '1')
  const { state, features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })

  const [resetFlex] = useMutation(RESET_FLEX)

  const openTab = () => {
    if (state.allowed) {
      resetFlex({
        variables: { resetFlex: true },
      })
      return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', link, activities, unit?.ActivePage)
    }

    link && window.open(link, '_self')
  }

  const openAI = () => {
    const lang = student?.student?.PortalLanguage
      ? student?.student?.PortalLanguage
      : learningPath?.learningPath?.details?.language
    unit?.AITutorLink && window.open(unit?.generateAITutorLink(lang), '_blank')
  }

  return (
    <>
      {!modalDisabled && (
        <RedoModal
          open={modalOpen}
          onClose={() => setModalOpen(!modalOpen)}
          onRedo={(disabled) => {
            openTab()
            if (disabled) {
              localStorage.setItem(LCRedoLocalstorageKey(SFID), '1')
            }
            setModalDisabled(disabled)
            setModalOpen(false)
          }}
        />
      )}
      {isLessonZero ? (
        <Lesson
          status="completed"
          title={cardTitle}
          isLessonZero={isLessonZero}
          language={unit?.StudentProgram?.program?.Language}
        >
          <Lesson0Wrapper>
            <CleanHeader>
              <Icon color="blue100">
                <IconCircle color="white" />
                <Tick />
              </Icon>
            </CleanHeader>
            <Spacer size="xxs" />
            <TagContainer>
              <Tag>{title}</Tag>
              <Tag>
                <FormattedMessage id="Completed" />
              </Tag>
            </TagContainer>
            <Spacer size="xs" />
            <div>
              <Lesson0Desc>
                <FormattedMessage
                  id="Well done! You’re ready to start your lessons"
                  defaultMessage="Well done! You’re ready to start your lessons"
                />
              </Lesson0Desc>
            </div>
            <Spacer size="md" />
            <div>
              {link && !isRedo && !unit?.HasContractEnded && (
                <Button color="default" disabled={!link} narrow onClick={() => openTab()}>
                  <FormattedMessage id="Redo" />
                </Button>
              )}
              {isRedo && !unit?.HasContractEnded && (
                <Button
                  color="success"
                  disabled={!continueLink}
                  narrow
                  onClick={() => {
                    if (state.allowed) {
                      return features.cr8.redirectToCR8(
                        unit?.LearningPathId ?? '',
                        continueLink,
                        activities,
                        unit.ActivePage
                      )
                    }

                    window.open(continueLink, '_self')
                  }}
                >
                  <FormattedMessage id="Continue" />
                </Button>
              )}
              <Spacer size="lg" />
            </div>

            <AITutorLink unit={unit} status="unlocked" txtColor="black" btnColor="brandPrimary" />
          </Lesson0Wrapper>
        </Lesson>
      ) : (
        <Lesson status="completed" title={title} isFromElective={unit?.IsFromElective} className="test">
          <LessonWrapper>
            <TagContainer className="status-tag">
              <Tag>{title}</Tag>
              <Tag>
                <FormattedMessage id="Completed" />
              </Tag>
            </TagContainer>
            <div>
              <LessonDesc>{cardTitle}</LessonDesc>
            </div>

            <ActivityRounds activities={activities} unit={unit} disabled={unit?.HasContractEnded} />
            <div>
              {link && !isRedo && !unit?.HasContractEnded && (
                <Button disabled={!link} ghost narrow onClick={() => (modalDisabled ? openTab() : setModalOpen(true))}>
                  <FormattedMessage id="Redo" />
                </Button>
              )}
              {isRedo && !unit?.HasContractEnded && (
                <Button
                  disabled={!continueLink}
                  narrow
                  onClick={() => {
                    if (state.allowed) {
                      return features.cr8.redirectToCR8(
                        unit?.LearningPathId ?? '',
                        continueLink,
                        activities,
                        unit?.ActivePage
                      )
                    }

                    window.open(continueLink, '_self')
                  }}
                >
                  <FormattedMessage id="Continue" />
                </Button>
              )}
              <Spacer size="xl" />
            </div>
          </LessonWrapper>

          <AITutorLink unit={unit} status="unlocked" txtColor="black" btnColor="brandPrimary" />
        </Lesson>
      )}
    </>
  )
}

export default Completed
